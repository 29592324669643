import React from 'react';
import {
  render
} from 'react-dom';
import App from "./components/App";
import { ToastContainer } from 'react-toastify';
import "assets/scss/material-kit-react.scss?v=1.9.0";
import "./components/Translations/i18n";
import 'react-toastify/dist/ReactToastify.css';

render( <>
  < App / >
  <ToastContainer
    position="top-right"
    autoClose={false}
    closeOnClick
  />
</> , document.querySelector('#root'));
