import loginAdminImg from "assets/img/admin_img.jpg";
import iconImg from "assets/img/logo_header.png";
import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params['baseUrl'] = 'https://redvital.aicor.es/';
params['apiUrl'] = 'https://api.redvital.aicor.es/api/';
params['redvitalBaseUrl'] = 'http://redvital.test/';
params['tokenFicheros'] = '583fd59f-051d-4fee-97e3-d15232d02dc2';
params['loginAdminImg'] = loginAdminImg;
params['sideBarIcon'] = iconImg;
params['sideBarImg'] = sideBarImg;
params['sideBarColor'] = '#EF465C';
params['firstColorRGB'] = 'rgb(239, 70, 92)';
params['firstColor'] = '#EF465C';
params['firstColorHover'] = '#F995A3';
params['secondColor'] = '#fff';
params['color_green'] = '#00C534';
params['color_red'] = '#FF0C56';
params['color_grey'] = '#BBBBBB';
params['proyectName'] = 'Redvital';
params['defaultTableLength'] = 10;
params['tableLengthArray'] = [10, 25, 50];
params['personalizacion'] = false;
params['dev_mode'] = true;
params['panel_url'] = 'administrador';
params['web_url'] = 'entidad';
params['GOOGLE_API_KEY'] = 'AIzaSyAfsDJ2Ee7XCcfvUg1xwEmk-XY5bRfbOO4';
params['jitsiDomain'] = 'meet.jit.si';

export default params;
