import React, { lazy, Suspense, useState, useEffect } from "react";
import UserParams from 'utils/UserParams';
import LoginAdmin from "views/admin/LoginAdmin";
import LoginWeb from "views/web/LoginPage/LoginPage";
import PARAMS from "utils/PARAMS";
import * as API from "utils/API_V2";
import { PusherProvider } from "@harelpls/use-pusher";
import GridContainer from "components/Grid/GridContainer";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { createBrowserHistory } from "history";

const Admin = lazy(() => import('layouts/Admin'));
const WebColegio = lazy(() => import('layouts/WebColegio'));
const WebSanitario = lazy(() => import('layouts/WebSanitario'));
const hist = createBrowserHistory();
const loading = () => <CustomLoading/>;

export default function App(prop) {

  const [apiToken, setApiToken] = useState(localStorage.getItem("apiToken") || "");
  const [user, setUser] = useState(null);
  const [login, setLogin] = useState(false);
  const [load, setLoad] = useState(false);

  const config = {
    // required config props
    clientKey: "913c2b20cdcd168d262b",
    cluster: "eu",

    // optional if you'd like to trigger events. BYO endpoint.
    // see "Trigger Server" below for more info
    // triggerEndpoint: "/pusher/trigger",

    // required for private/presence channels
    // also sends auth headers to trigger endpoint
    // authEndpoint: "/pusher/auth",
    // auth: {
    //   headers: { Authorization: "Bearer token" },
    // },
  };

  const isAdmin = async(e) => {
    let res;
    if (apiToken != '') {
      res = await API.isAdminAuth();
    }else {
      res = await API.isAdminGuest();
    }

    if (res.error) {
      setLogin(false)
    }else{
      setUser(res.data)
      setLogin(true)
    }
    setLoad(true)
  }

  useEffect(() => {
    isAdmin();
  }, []);


  if (load) {
    if(login){
      return (<>
        <PusherProvider {...config}>
          <Suspense fallback={loading()}>
            <Router history={hist}>
              {(user.rol == 'admin') && <Switch>
                <Route path="/" component={(props) => <Admin user={user} {...props} />} />
              </Switch>}
              {(user.rol == 'colegio') && <Switch>
                <Route path="/" component={(props) => <WebColegio user={user} {...props} />} />
              </Switch>}
              {(user.rol == 'sanitario') && <Switch>
                <Route path="/" component={(props) => <WebSanitario user={user} {...props} />} />
              </Switch>}
            </Router>
          </Suspense>
        </PusherProvider>
      </>);
    }else{
      switch (true) {
        case window.location.href.includes(PARAMS.panel_url):
          return(<LoginAdmin setApiToken={(token) => setApiToken(token)} />)
          break;
        default:
          return(<LoginWeb setApiToken={(token) => setApiToken(token)} />)
      }
    }

  }else{
    return(
      <CustomLoading/>
    )
  }

}
