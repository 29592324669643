import {useState, useEffect} from "react";
// import {instance as API, instance_blob as API_BLOB, instance_archivo as API_ARCHIVO} from "utils/API_V2";
import { toast } from 'react-toastify';
var time = [];

// Recibe array map
export function validateFields(fields){
  var BreakException = {};
  var error = [];
  error['status'] = true;
  var field = '';

  try {
    fields.forEach(function(el, i){
      if ((el === '' || el === null || el === []) && error) {
        error['status'] = false;
        field = i;
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }

  if (!error.status) {
    error['message'] = 'El campo '+field+' no puede estar vacio';
  }

  return error;
}

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    resolved.error = e;
  }

  return resolved;
}
