// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from 'react-toastify';

// Instancia para api de partes
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

if (localStorage.getItem("apiToken")) {
  instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
}
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          axios.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }

  return Promise.reject(error);
});

// Instancia para api de partes
var instance_blob = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

instance_blob.defaults.responseType = 'blob';
if (localStorage.getItem("apiToken")) {
  instance_blob.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
}
instance_blob.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          axios.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }

  return Promise.reject(error);
});

// Instancia para ws formData
var instance_fd = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 5000
});

if (localStorage.getItem("apiToken")) {
  instance_fd.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
}
instance_fd.defaults.headers.common["Content-Type"] = "multipart/form-data";
instance_fd.interceptors.response.use(async function (response) {
  return response;
}, async function (error) {
  console.log(error.response);

  if (error && error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          axios.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }
});

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    if (e.response && e.response.data && e.response.data.error) {
      resolved.error = e.response.data.error;
    }else{
      resolved.error = 'Se ha producido un error';
    }

  }

  return resolved;
}

// File a base64
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  return await resolve(
    instance.post('isAdminAuth').then(res => res.data)
  )
}

// Ruta para verificar tipo de usuario (no token)
export async function isAdminGuest() {
  return await resolve(
    instance.post('isAdminGuest').then(res => res.data)
  )
}

export async function login(email, password, userType) {
  return await resolve(
    instance.post('login', { email, password, userType }).then(res => res.data)
  )
}

export async function register(nombre, apellidos, nif, email, password, userType) {
  return await resolve(
    instance.post('register', { nombre, apellidos, nif, email, password, userType }).then(res => res.data)
  )
}

export async function resetPass(email) {
  return await resolve(
    instance.post('resetPassword/create', { email }).then(res => res.data)
  )
}

export async function logout() {
  return await resolve(
    instance.get('logout').then(res => res.data)
  )
}

export async function getPermiso() {
  return await resolve(
    instance.post('permiso/index').then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Usuarios --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function getUser() {
  return await resolve(
    instance.post('usuarios/show').then(res => res.data)
  )
}

export async function userIndex(search, page, perPageData) {
  return await resolve(
    instance.post('usuarios/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function userStore(nombre, email, permiso_id, password, apellidos, puesto, company) {
  return await resolve(
    instance.post('usuarios/store', { nombre, email, permiso_id, password, apellidos, puesto, company }).then(res => res.data)
  )
}

export async function userUpdate(usuario, pass) {

  let fd = new FormData();

  for (var key in usuario) {
    if (usuario[key]) {
      fd.append(key, usuario[key]);
    }
  }

  if (pass) {
    fd.append('password', pass);
  }

  return await resolve(
    instance_fd.post(`usuarios/update/${usuario.id}`, fd).then(res => res.data)
  )
}

export async function userDestroy(id) {
  return await resolve(
    instance.post(`usuarios/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Logs --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function logIndex() {
  return await resolve(
    instance.post('log/index').then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Permisos --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function permisosList(search) {
  return await resolve(
    instance.post('permiso/list', { search }).then(res => res.data)
  )
}

export async function permisoStore(nombre, permiso_modelos) {
  return await resolve(
    instance.post('permiso/store', { nombre, permiso_modelos }).then(res => res.data)
  )
}

export async function permisoUpdate(id, nombre, permiso_modelos) {
  return await resolve(
    instance.post(`permiso/update/${id}`, { nombre, permiso_modelos }).then(res => res.data)
  )
}

export async function permisoDestroy(id) {
  return await resolve(
    instance.post(`permiso/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Colegios --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function colegiosAll() {
  return await resolve(
    instance.post('colegios/all').then(res => res.data)
  )
}

export async function colegioActivo(id, value) {
  return await resolve(
    instance.post(`colegios/activo/${id}`, {value}).then(res => res.data)
  )
}


export async function colegiosIndex(search, page, perPageData) {
  return await resolve(
    instance.post('colegios/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function colegioStore(colegio) {
  return await resolve(
    instance.post('colegios/store', colegio).then(res => res.data)
  )
}

export async function colegioUpdate(colegio) {
  return await resolve(
    instance.post(`colegios/update/${colegio.id}`, colegio).then(res => res.data)
  )
}

export async function colegioDestroy(id) {
  return await resolve(
    instance.post(`colegios/destroy/${id}`).then(res => res.data)
  )
}

export async function colegioSync() {
  return await resolve(
    instance.post(`colegios/sync`).then(res => res.data)
  )
}

export async function colegioSanitario() {
  return await resolve(
    instance.post(`colegios/sanitario`).then(res => res.data)
  )
}

export async function colegioAlumnos(search, page, perPageData) {
  return await resolve(
    instance.post(`colegios/alumnos_colegio`, { search, page, perPageData }).then(res => res.data)
  )
}

export async function colegioAlumnosList(id, search) {
  return await resolve(
    instance.post(`colegios/alumnos_colegio_list/${id}`, {search}).then(res => res.data)
  )
}


/** --------------------------------------------------------------------------------------
* ----------------------------------- Sanitarios --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function sanitariosIndex(search, page, perPageData) {
  return await resolve(
    instance.post('sanitarios/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function sanitarioActivo(id, value) {
  return await resolve(
    instance.post(`sanitarios/activo/${id}`, {value}).then(res => res.data)
  )
}

export async function sanitarioStore(sanitario) {
  return await resolve(
    instance.post('sanitarios/store', sanitario).then(res => res.data)
  )
}

export async function sanitarioUpdate(sanitario) {
  return await resolve(
    instance.post(`sanitarios/update/${sanitario.id}`, sanitario).then(res => res.data)
  )
}

export async function sanitarioDestroy(id) {
  return await resolve(
    instance.post(`sanitarios/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Eventos --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function eventosIndex() {
  return await resolve(
    instance.post('eventos/index').then(res => res.data)
  )
}

export async function eventoStore(evento) {
  return await resolve(
    instance.post('eventos/store', evento).then(res => res.data)
  )
}

export async function eventoUpdate(evento) {
  return await resolve(
    instance.post(`eventos/update/${evento.id}`, evento).then(res => res.data)
  )
}

export async function eventoDestroy(id) {
  return await resolve(
    instance.post(`eventos/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Llamadas --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function llamadasIndex(search_colegio, search_sanitario, search_alumno, page, perPageData) {
  return await resolve(
    instance.post('llamadas/index', { search_colegio, search_sanitario, search_alumno, page, perPageData }).then(res => res.data)
  )
}

export async function llamadaStore(llamada) {
  return await resolve(
    instance.post('llamadas/store', llamada).then(res => res.data)
  )
}

export async function llamadaUpdate(llamada) {
  return await resolve(
    instance.post(`llamadas/update/${llamada.id}`, llamada).then(res => res.data)
  )
}

export async function llamadaDestroy(id) {
  return await resolve(
    instance.post(`llamadas/destroy/${id}`).then(res => res.data)
  )
}

export async function llamadaShow(id) {
  return await resolve(
    instance.post(`llamadas/show/${id}`).then(res => res.data)
  )
}

export async function llamadaIniciar(id) {
  return await resolve(
    instance.post(`llamadas/iniciar/${id}`).then(res => res.data)
  )
}

export async function llamadaCerrar(id) {
  return await resolve(
    instance.post(`llamadas/cerrar/${id}`).then(res => res.data)
  )
}

export async function llamadasPendientes() {
  return await resolve(
    instance.post(`llamadas/pendientes`).then(res => res.data)
  )
}

export async function sendNotification(id, mensaje, tipo, destinatario) {
  return await resolve(
    instance.post(`llamadas/sendNotification`, {id, mensaje, tipo, destinatario}).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Adjuntos Llamadas --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function llamadasAdjuntosIndex(search, page, perPageData) {
  return await resolve(
    instance.post('adjuntos_llamadas/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function llamadaAdjuntoStore(adjunto) {
  let fd = new FormData();

  for (var key in adjunto) {
    if (adjunto[key]) {
      fd.append(key, adjunto[key]);
    }
  }
  return await resolve(
    instance_fd.post('adjuntos_llamadas/store', fd).then(res => res.data)
  )
}

export async function llamadaAdjuntoDestroy(id) {
  return await resolve(
    instance.post(`adjuntos_llamadas/destroy/${id}`).then(res => res.data)
  )
}

export async function llamadaAdjuntoShow(id) {
  return await resolve(
    instance.post(`adjuntos_llamadas/show/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Alumnos --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function alumnosIndex(search, page, perPageData) {
  return await resolve(
    instance.post('alumnos/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function alumnoShow(id) {
  return await resolve(
    instance.post(`alumnos/show/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Ficheros --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function ficherosIndex(search, page, perPageData) {
  return await resolve(
    instance.post('ficheros/index', { search, page, perPageData }).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Actuación --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function actuacionesIndex(search, page, perPageData) {
  return await resolve(
    instance.post('actuacion/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function actuacionesHistorial(id) {
  return await resolve(
    instance.post(`actuacion/historial/${id}`).then(res => res.data)
  )
}
export async function actuacionesGenerarInforme(informe) {
  return await resolve(
    instance_blob.post(`actuacion/generar_informe/${informe.actuacion_id}`, informe).then(res => res.data)
  )
}

export async function actuacionShow(id) {
  return await resolve(
    instance.post(`actuacion/show/${id}`).then(res => res.data)
  )
}

export async function actuacionStore(actuacion) {
  return await resolve(
    instance.post(`actuacion/store`, actuacion).then(res => res.data)
  )
}

export async function actuacionExport(id) {
  return await resolve(
    instance.post(`actuacion/export/${id}`).then(res => res.data)
  )
}

export async function actuacionDestroy(id) {
  return await resolve(
    instance.post(`actuacion/destroy/${id}`).then(res => res.data)
  )
}


/** --------------------------------------------------------------------------------------
* ----------------------------------- Archivos --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function descargarArchivo(url) {
  let nombre = url.split('/');
  nombre = nombre[nombre.length-1];
  return await resolve(
    instance.post(`archivos/descargarArchivo`, {url}).then(res => {
      var a = document.createElement("a");
      a.href = res.data;
      a.download = nombre;
      a.click();
    })
  )
}

export async function eliminarArchivo(url, tipo, id) {
  return await resolve(
    instance.post(`archivos/eliminarArchivo`, {url, tipo, id}).then(res => res.data)
  )
}
